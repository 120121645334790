import React, { useState, useEffect } from 'react';
import './App.css';
import BookLibrary from './views/BookLibrary';
import WelcomePage from './views/WelcomePage';
import { Menu, X } from 'lucide-react';

interface HeaderProps {
  onNavigateToCategories: () => void;
  onNavigateToAllBooks: () => void;
  onBackToWelcome: () => void;
  currentView: 'welcome' | 'categories' | 'books';
  onMenuToggle: (isMenuOpen: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ onNavigateToCategories, onNavigateToAllBooks, onBackToWelcome, currentView, onMenuToggle }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    onMenuToggle(!isMenuOpen); // Notify parent component about menu state change
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    onMenuToggle(false); // Notify parent component about menu state change
  };

  const NavLinks = () => (
    <>
      {currentView !== 'welcome' && (
        <li>
          <button onClick={() => { onBackToWelcome(); closeMenu();}} className="text-white hover:text-cyan-300 transition-colors">
            Home
          </button>
        </li>
      )}

      {currentView === 'welcome' && (
        <>
        {/* <li>
          <button  onClick={() => { onNavigateToCategories(); closeMenu();}} className="text-white hover:text-cyan-300 transition-colors">
            Browse Categories
          </button>
        </li> */}
        <li>
          <button  onClick={() => { onNavigateToAllBooks(); closeMenu();}} className="text-white hover:text-cyan-300 transition-colors">
            Browse All Books
          </button>
        </li>
        </>
      )}
    </>
  );

  return (
    <header className="fixed top-0 left-0 right-0 bg-cyan-950 text-white p-4 z-10">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Good Muslim Reads</h1>
        <nav>
          {/* Desktop menu  hidden md:*/}
          <ul className="flex space-x-6">
            <NavLinks />
          </ul>
          {/* Mobile menu button */}
          {/* <button onClick={toggleMenu} className="md:hidden">
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button> */}
        </nav>
      </div>
      {/* Mobile dropdown menu */}
      {isMenuOpen && (
        <nav className="md:hidden mt-4">
          <ul className="flex flex-col space-y-2">
            <NavLinks />
          </ul>
        </nav>
      )}
    </header>
  );
};

const Footer: React.FC = () => (
  <footer className="fixed bottom-0 left-0 right-0 bg-gray-200 p-4 text-center">
    <p>2024 Good Muslim Reads. All rights reserved.</p>
    {/* <p>email@email.com</p> */}
  </footer>
);

// New Google AdSense component
const GoogleAd: React.FC = () => {
  return (
    <div className="ad-container">
      <ins className="adsbygoogle"
           style={{ display: 'block' }}
           data-ad-client="ca-pub-3130718021001054"
           data-ad-slot="3655270675"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
    </div>
    // <div className="bg-gray-100 border border-gray-300 p-4 rounded-lg shadow-md w-full max-w-[400px] h-[150px] mx-auto">
    //   <div className="flex flex-col items-center justify-center h-full">
    //     <div className="text-xl font-bold text-gray-500 mb-2">Advertisement</div>
    //     <div className="text-sm text-gray-400">300x250</div>
    //     <div className="mt-4 w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center">
    //       <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    //         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
    //       </svg>
    //     </div>
    //     <div className="mt-4 text-xs text-gray-400">Your ad could be here</div>
    //   </div>
    // </div>
  );
};

const App: React.FC = () => {
  const [currentView, setCurrentView] = useState<'welcome' | 'categories' | 'books'>('welcome');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // New state for menu

  const navigateToCategories = () => {
    setCurrentView('categories');
    setSelectedCategory(null);
  };

  const navigateToAllBooks = () => {
    setCurrentView('books');
    setSelectedCategory('all');
  };

  const navigateToWelcome = () => {
    setCurrentView('welcome');
  };

  const handleMenuToggle = (isOpen: boolean) => {
    setIsMenuOpen(isOpen); // Update state when the menu is toggled
  };

  return (
    <div className="App min-h-screen">
      <Header 
        onNavigateToCategories={navigateToCategories}
        onNavigateToAllBooks={navigateToAllBooks}
        onBackToWelcome={navigateToWelcome}
        currentView={currentView}
        onMenuToggle={handleMenuToggle} // Pass menu toggle handler
      />

      <main className="pt-16 pb-16 pl-0 transition-all duration-300 ease-in-out">
        <div className={"transition-all duration-300 ease-in-out ml-0"}>
          {currentView === 'welcome' && (
            <WelcomePage 
              onNavigateToCategories={navigateToCategories}
              onNavigateToAllBooks={navigateToAllBooks}
            />
          )}
          {currentView !== 'welcome' && (
            <BookLibrary 
              initialCategory={selectedCategory}
              onBackToWelcome={navigateToWelcome}
              onNavigateToCategories = {navigateToCategories}
              onNavigateToAllBooks = {navigateToAllBooks}
              isMenuOpen={isMenuOpen}

            />
          )}
        </div>
      </main>

      <Footer />

      {/* Add Google AdSense component */}
      <div className="fixed bottom-16 left-0 right-0">
        <GoogleAd />
      </div>

    </div>
  );
}

export default App;