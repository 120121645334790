import React, { useState, useMemo } from 'react';
import { Search, Book, ArrowLeft, ChevronLeft, ChevronRight } from 'lucide-react';
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Badge } from "../components/ui/badge";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../components/ui/card";
import { categories, books } from "../data/library";

export interface Category {
  id: string;
  name: string;
  description: string;
}

export interface Book {
  id: number;
  title: string;
  author: string;
  category: string;
  tags: string[];
  description: string;
  image: string;
  buyLink: string;
}

interface BookLibraryProps {
  initialCategory: string | null;
  onBackToWelcome: () => void;
  onNavigateToCategories: () => void;
  onNavigateToAllBooks:  () => void;
  isMenuOpen: boolean;
}

const ITEMS_PER_PAGE = 20;

const BookLibrary: React.FC<BookLibraryProps> = ({ initialCategory, onBackToWelcome,  onNavigateToCategories, onNavigateToAllBooks, isMenuOpen }) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(initialCategory);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const filteredBooks = useMemo(() => {
    return books.filter(book => 
      (selectedCategory === null || selectedCategory === 'all' || book.category === selectedCategory) &&
      (searchTerm === '' || book.title.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (selectedTags.length === 0 || selectedTags.every(tag => book.tags.includes(tag)))
    );
  }, [selectedCategory, searchTerm, selectedTags]);

  const totalPages = Math.ceil(filteredBooks.length / ITEMS_PER_PAGE);

  const paginatedBooks = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return filteredBooks.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  }, [filteredBooks, currentPage]);

  const allTags = useMemo(() => {
    return filteredBooks.reduce((tags: string[], book) => {
      book.tags.forEach(tag => {
        if (!tags.includes(tag)) {
          tags.push(tag);
        }
      });
      return tags;
    }, []);
  }, [filteredBooks]);

  const renderCategories = () => (
    <>
      {/* <Button variant="ghost" className="mb-4" onClick={onBackToWelcome}>
        <ArrowLeft className="mr-2 h-4 w-4" /> Back to Welcome Page
      </Button> */}
      <h1 className="text-3xl font-bold mb-6">Browse Islamic Books By Category</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <Card className="cursor-pointer hover:shadow-lg transition-shadow" onClick={() => setSelectedCategory('all')}>
          <CardHeader>
            <CardTitle>All Books</CardTitle>
          </CardHeader>
          <CardContent>
            <CardDescription>Browse our entire collection of Islamic books</CardDescription>
          </CardContent>
        </Card>
        {categories.map((category: Category) => (
          <Card key={category.id} className="cursor-pointer hover:shadow-lg transition-shadow" onClick={() => setSelectedCategory(category.id)}>
            <CardHeader>
              <CardTitle>{category.name}</CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>{category.description}</CardDescription>
            </CardContent>
          </Card>
        ))}
      </div>
    </>
  );

  const renderBooks = (isMenuOpen:boolean) => (
    <div className="flex flex-col min-h-screen">
      <div  className={`fixed left-0 right-0 bg-background z-10 p-4 ${isMenuOpen ? 'top-40' : 'top-16'}`}>
        {/* <Button variant="ghost" className="mb-4" onClick={() => setSelectedCategory(null)}>
          <ArrowLeft className="mr-2 h-4 w-4" /> Browse Categories 
        </Button> */}
        <Input
          type="text"
          placeholder="Search books..."
          value={searchTerm}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(e.target.value);
            setCurrentPage(1);
          }}
          className="w-full mb-4"
        />
        <h2 className="text-xl font-semibold mb-2">Filter by tags:</h2>
        <div className="flex flex-wrap gap-2 mb-4">
          {allTags.map((tag: string) => (
            <Badge 
              key={tag} 
              variant={selectedTags.includes(tag) ? "default" : "outline"}
              className="cursor-pointer"
              onClick={() => {
                setSelectedTags(prev => 
                  prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]
                );
                setCurrentPage(1);
              }}
            >
              {tag}
            </Badge>
          ))}
        </div>
      </div>

      <div className="mt-56 space-y-6">
        {paginatedBooks.map((book: Book) => (
          <Card key={book.id} className="flex border rounded-lg p-6 shadow-md">
            <div className="flex-shrink-0 w-40 h-60 mr-6">
              <img 
                src={book.image} 
                alt={book.title} 
                className="w-full h-full object-cover rounded"
              />
            </div>
            <div className="flex flex-col justify-between flex-grow">
              <div>
                <CardTitle className="text-2xl font-semibold mb-2">{book.title}</CardTitle>
                <CardDescription className="text-gray-600 mb-2">by {book.author}</CardDescription>
                <p className="text-sm mb-4">{book.description}</p>
                <div className="flex flex-wrap gap-1 mb-4">
                  {book.tags.map(tag => (
                    <Badge key={tag} variant="secondary" className="text-xs">
                      {tag}
                    </Badge>
                  ))}
                </div>
              </div>
              <div className="flex">
                <Button 
                  className="font-bold text-sm px-4 py-2" 
                  asChild
                >
                  <a href={book.buyLink} target="_blank" rel="noopener noreferrer">Shop Now</a>
                </Button>
              </div>
            </div>
          </Card>
        ))}
      </div>

      <div className="flex justify-center items-center mt-6 mb-6">
        <Button
          variant="outline"
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          <ChevronLeft className="h-4 w-4 mr-2" />
          Previous
        </Button>
        <span className="mx-4">
          Page {currentPage} of {totalPages}
        </span>
        <Button
          variant="outline"
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Next
          <ChevronRight className="h-4 w-4 ml-2" />
        </Button>
      </div>
    </div>
  );

  return (
    <div className="p-4">
      {selectedCategory ? renderBooks(isMenuOpen) : renderCategories()}
    </div>
  );
};

export default BookLibrary;