import React from 'react';
import { Button } from "../components/ui/button";

interface WelcomePageProps {
  onNavigateToCategories: () => void;
  onNavigateToAllBooks: () => void;
}

const WelcomePage: React.FC<WelcomePageProps> = ({ onNavigateToCategories, onNavigateToAllBooks }) => {
  return (
    <div className="max-w-4xl mx-auto p-8 text-center">
      <h1 className="text-3xl font-bold mb-6">Greetings to all seekers of knowledge!</h1>
      <p className="mb-4 text-xl">
        We offer a carefully curated collection of authentic Islamic books based on the Quran and Sunnah (Prophet Muhammad's (PBUH) words, actions, and practices).
      </p>
      <p className="mb-4 text-xl">
        Our goal is to provide essential texts for Muslims of all backgrounds, new converts, and those interested in learning about Islam. The selection covers various topics including Tafsir, Fiqh, Seerah, and contemporary issues, all chosen for their reliability and relevance.
      </p>
      <p className="mb-4 text-xl">
         We aim to simplify access to trustworthy Islamic literature and are continuously searching for beneficial books to add to our collection.
      </p>
      <p className="mb-8 text-xl">
        May Allah guide us all on the straight path.
      </p>
      <p className="font-bold mb-8">Good Muslim Reads Team</p>
      <div className="flex justify-center space-x-4">
        {/* <Button onClick={onNavigateToCategories}>
          Browse Categories
        </Button> */}
        <Button onClick={onNavigateToAllBooks}>
          Browse All Books
        </Button>
      </div>
    </div>
  );
};

export default WelcomePage;