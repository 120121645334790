import  {Category, Book} from "../views/BookLibrary";

// Mock data
export const categories: Category[] = [
    { id: 'quraan', name: 'Quraans', description: 'Arabic Quraans and translations' },
    { id: 'islam-intro', name: 'Introduction to Islam', description: 'Offers essential resources for understanding islams core beliefs' },
    { id: 'tafsir', name: 'Tafsirs (Quranic Exegesis)', description: 'Explanations of the Holy Quraan' },
    { id: 'kids', name: 'Kids', description: 'Islamic resources for Kids' },
    { id: 'hadith', name: 'Hadith Collections', description: 'Authentic says of Muhammad (PBUH)' },
    { id: 'fiqh', name: 'Fiqh (Islamic Jurisprudence)', description: 'Guidelines, rules and regulations based on the four schools of thought' },
    { id: 'islam-history', name: 'Islamic History', description: 'Life of the Prophet and early Islam' },
    { id: 'aqidah', name: 'Aqidah (Islamic Theology)', description: 'Islamic core beliefs and tenets of faith' }
    // { id: 'woman', name: 'Islamic Women’s Studies', description: 'Life stories of notable individuals' },
    // { id: 'shariah', name: 'Islamic Law (Shariah)', description: 'Life stories of notable individuals' },
    // { id: 'biography', name: 'Sufism and Spirituality', description: 'Life stories of notable individuals' },
    // { id: 'biography', name: 'Islamic Philosophy', description: 'Life stories of notable individuals' },
    // { id: 'biography', name: 'Islamic Sciences', description: 'Life stories of notable individuals' },
    // { id: 'biography', name: 'Biographies of Islamic Scholars', description: 'Life stories of notable individuals' },
    // { id: 'biography', name: 'Islamic Ethics and Morality', description: 'Life stories of notable individuals' },
    // { id: 'biography', name: 'Contemporary Islamic Issues', description: 'Life stories of notable individuals' },
    // { id: 'biography', name: 'Islamic Arts and Culture', description: 'Life stories of notable individuals' },
    // { id: 'biography', name: 'Islamic Economics and Finance', description: 'Life stories of notable individuals' },
    // { id: 'biography', name: 'Islamic Politics and Governance', description: 'Life stories of notable individuals' },
    // { id: 'biography', name: 'Islamic Education and Pedagogy', description: 'Life stories of notable individuals' },
    // { id: 'biography', name: 'Islamic Poetry and Literature', description: 'Life stories of notable individuals' },
    // { id: 'biography', name: 'Islamic Health and Medicine', description: 'Life stories of notable individuals' },
    // { id: 'biography', name: 'Islamic Eschatology (End Times)', description: 'Life stories of notable individuals' },
    // { id: 'biography', name: 'Islamic Military History', description: 'Life stories of notable individuals' },
];
  
export const books: Book[] = [
    { 
        id: 10, 
        title: 'No god but God', 
        author: 'Reza Aslan', 
        category: 'islam-intro', 
        tags: ['beginner', 'english'], 
        description: 'Detailed yet accessible exploration of Islams history, beliefs, and its evolution into modern times. Its great for those wanting to understand both the spiritual and socio-political dimensions of Islam', 
        image: require('../images/introduction/no-god-but-god.png'), 
        buyLink: 'https://amzn.to/4dmdVt2' 
    },
    { 
        id: 20, 
        title: 'Islam: A Short History', 
        author: 'Karen Armstrong ', 
        category: 'islam-intro', 
        tags: ['beginner', 'english'], 
        description: 'Karen Armstrongs concise account covers the history of Islam from its inception to modern times, making it an engaging read for those looking to understand Islams role throughout history​', 
        image: require('../images/introduction/islam-a-short-history.png'), 
        buyLink: 'https://amzn.to/4dn7sxO' 
    },
    { 
        id: 30, 
        title: 'How to Pray: A Step-by-Step Guide to Prayer in Islam', 
        author: ' Mustafa Umar', 
        category: 'islam-intro', 
        tags: ['beginner', 'english'], 
        description: 'Provides clear instructions on performing Salah, detailing the postures, recitations, and spiritual significance of each step. Ideal for beginners and those looking to improve their practice, it emphasizes the importance of prayer in Islam', 
        image: require('../images/introduction/how-to-pray.png'), 
        buyLink: 'https://amzn.to/4gAmNy1' 
    },
    { 
        id: 40, 
        title: 'Welcome to Islam', 
        author: 'Mustafa Umar', 
        category: 'islam-intro', 
        tags: ['beginner', 'english'], 
        description: 'Offers a concise introduction to the basic beliefs and practices of Islam. It covers essential topics such as the Five Pillars, the Quran, and the life of the Prophet Muhammad, providing an accessible guide for those new to the faith or seeking to learn more.', 
        image: require('../images/introduction/welcome-to-islam.png'), 
        buyLink: 'https://amzn.to/4dlIH59' 
    },
    { 
        id: 100, 
        title: 'Arabic Quraan', 
        author: 'Allah', 
        category: 'quraan', 
        tags: ['quraan', 'arabic'], 
        description: '13 line arabic quraan with color coded tajweed rules', 
        image: require('../images/quraan/quraan1.png'), 
        buyLink: 'https://amzn.to/3XKBZzI' 
    },
    { 
        id: 200, 
        title: 'Arabic Quraan', 
        author: 'Allah', 
        category: 'quraan', 
        tags: ['arabic', 'quraan'], 
        description: '15 line Madinah text arabic quraan.', 
        image: require('../images/quraan/quraan2.png'), 
        buyLink: 'https://amzn.to/3XA1eow' 
    },
    { 
        id: 400, 
        title: 'English-Arabic Quraan', 
        author: 'Mufti Afzal Hoosen Elias (translator) ', 
        category: 'quraan', 
        tags: ['arabic', 'english', 'quraan'], 
        description: 'Quraan Made Easy with inline commentary', 
        image: require('../images/quraan/english-arabic-quraan1.png'), 
        buyLink: 'https://amzn.to/4dttqzg' 
    },
    { 
        id: 500, 
        title: 'English-Arabic Quraan', 
        author: ' Dr. Mustafa Khattab (translator) ', 
        category: 'quraan', 
        tags: ['arabic', 'english', 'quraan'], 
        description: 'The Clear Quraan', 
        image: require('../images/quraan/english-arabic-quraan2.png'), 
        buyLink: 'https://amzn.to/4ei4a01' 
    },
    { 
        id: 600, 
        title: 'English Quraan', 
        author: 'Abdullah Yusuf Ali (translator) ', 
        category: 'quraan', 
        tags: ['english','quraan'], 
        description: 'The Holy Quraan', 
        image: require('../images/quraan/english-quraan1.png'), 
        buyLink: 'https://amzn.to/3U8TzNd' 
    },
    { 
        id: 700, 
        title: 'English Quraan', 
        author: 'M. A. S. Abdel Haleem (translator) ', 
        category: 'quraan', 
        tags: ['english', 'quraan'], 
        description: 'The Quraan (Oxford Worlds Classics)', 
        image: require('../images/quraan/english-quraan2.png'), 
        buyLink: 'https://amzn.to/3zIS40T' 
    },
    { 
        id: 1000, 
        title: 'Tafsir al-Tabari', 
        author: 'Ibn Jarir al-Tabari', 
        category: 'tafsir', 
        tags: ['tafsir (quranic exegesis)', 'arabic', 'collection'], 
        description: 'Known for its extensive use of hadith and linguistic analysis, its considered one of the earliest and most influential Quranic commentaries.', 
        image: require('../images/tafsir/tabri-arabic.png'), 
        buyLink: 'https://amzn.to/3XFKiNy' 
    },
    { 
        id: 1010, 
        title: 'Tafsir Ibn Kathir', 
        author: 'Ibn Kathir', 
        category: 'tafsir', 
        tags: ['tafsir (quranic exegesis)', 'arabic', 'collection'], 
        description: 'Known for its focus on interpreting the Quran through other Quranic verses and authentic hadiths, making it popular among traditionalist Muslims.', 
        image: require('../images/tafsir/kathir-arabic.png'), 
        buyLink: 'https://amzn.to/3zMp48u' 
    },
    { 
        id: 1020, 
        title: 'Tafsir Ibn Kathir', 
        author: 'Ibn Kathir', 
        category: 'tafsir', 
        tags: ['tafsir (quranic exegesis)', 'english', 'collection'], 
        description: 'Known for its focus on interpreting the Quran through other Quranic verses and authentic hadiths, making it popular among traditionalist Muslims.', 
        image: require('../images/tafsir/kathir.png'), 
        buyLink: 'https://amzn.to/4egPcHN' 
    },
    { 
        id: 1025, 
        title: 'Tafsir al-Sadi', 
        author: 'Abd al-Rahman al-Sadi', 
        category: 'tafsir', 
        tags: ['tafsir (quranic exegesis)', 'english', 'collection'], 
        description: 'A modern tafsir known for its clarity and accessibility, focusing on deriving practical benefits and moral lessons from the Quranic text.', 
        image: require('../images/tafsir/sadi.png'), 
        buyLink: 'https://amzn.to/4egPcHN' 
    },
    
    { 
        id: 1100, 
        title: 'A Summary of Islamic Jurisprudence', 
        author: 'Salih Al-Fawzan', 
        category: 'fiqh', 
        tags: ['fiqh (islamic jurisprudence)', 'english'], 
        description: 'An introduction and explanation of fiqh and how the rulings are derived', 
        image: require('../images/fiqh/summary.png'), 
        buyLink: 'https://amzn.to/4gEw5Jg' 
    },
    
    { 
        id: 1101, 
        title: 'Mukhtasar Al-Quduri', 
        author: 'Imam Abul Husayn Ahmad Ibn Muhammad Ibn Ahmad Ibn Jafar ', 
        category: 'fiqh', 
        tags: ['hanafi', 'fiqh (islamic jurisprudence)', 'english'], 
        description: 'A Manual of Islamic Law According to the Hanafi School. This is a widely respected text in the Hanafi school, offering a concise overview of key issues in Islamic jurisprudence​', 
        image: require('../images/fiqh/quduri.png'), 
        buyLink: 'https://amzn.to/3BiNoiR' 
    },
    { 
        id: 1102, 
        title: 'Al-Shafis Risala', 
        author: 'Muhammad ibn Idris al-Shafii (Author), Majid Khadduri (Translator)', 
        category: 'fiqh', 
        tags: ['shafi', 'fiqh (islamic jurisprudence)', 'english'], 
        description: 'It is regarded as one of the earliest texts on usul al-fiqh (principles of Islamic jurisprudence). In this book, Imam al-Shafii outlines the sources of Islamic law, including the Quran, Hadith, consensus (ijma), and analogical reasoning (qiyas), and provides a framework for how these sources should be used to derive rulings.', 
        image: require('../images/fiqh/shafii.png'), 
        buyLink: 'https://amzn.to/3Y0H6xa' 
    },
    { 
        id: 1103, 
        title: 'Mukhtasar al-Akhdari', 
        author: 'abd Ar-Rahman Al-Akhdari (Author), Aisha Abdurrahman Bewley (Translator)', 
        category: 'fiqh', 
        tags: ['maliki', 'fiqh (islamic jurisprudence)', 'english'], 
        description: 'Summary on worshipping according to the School of Imam Malik', 
        image: require('../images/fiqh/maliki.png'), 
        buyLink: 'https://amzn.to/4gDU6Ad' 
    },
    
    { 
        id: 1200, 
        title: 'Islamic Creed', 
        author: 'by Ibn Taymiyyah (Author), Al Reshah (Translator)', 
        category: 'aqidah', 
        tags: ['aqidah (islamic theology)', 'english'], 
        description: 'Written by the renowned scholar Ibn Taymiyyah in the 13th century. It outlines the fundamental beliefs of mainstream Sunni Islam, focusing on the attributes of Allah, the nature of faith, and the correct understanding of Islamic monotheism (Tawhid). ', 
        image: require('../images/aqidah/islamic-creed.png'), 
        buyLink: 'https://amzn.to/4gEwVpn' 
    },
    { 
        id: 1202, 
        title: 'Kitab al-Tawheed (The Book of Monotheism)', 
        author: ' Ibn Hazm (Author), Renascence Foundation (Translator)', 
        category: 'aqidah', 
        tags: ['aqidah (islamic theology)', 'english'], 
        description: 'It focuses entirely on the concept of Tawhid (Islamic monotheism), which is the foundation of the Islamic faith. The book systematically explains the oneness of Allah (God) in worship, names, and attributes, while also warning against all forms of polytheism (shirk).', 
        image: require('../images/aqidah/mono.png'), 
        buyLink: 'https://amzn.to/47IZC0e' 
    },
    
    { 
        id: 1300, 
        title: 'Sahih Al-Bukhari', 
        author: 'Imam Al Bukhari (Compiled), Muhammad Muhsin Khan (Translator)', 
        category: 'hadith', 
        tags: ['hadith', 'arabic', 'english', 'collection'], 
        description: 'It is widely regarded as the most authentic collection of Hadith. It contains about 7,275 hadiths with repetitions, covering all aspects of Islamic life, including rituals, beliefs, and ethical guidelines.', 
        image: require('../images/hadith/bukhari.png'), 
        buyLink: 'https://amzn.to/4ewGPaY' 
    },
    
    { 
        id: 1301, 
        title: 'Sahih Muslim', 
        author: 'Imam Abul-Husain Muslim (Compiled), Adil Salahi (Translator), Imam Al-Nawawi (Commentary)', 
        category: 'hadith', 
        tags: ['hadith', 'arabic', 'english', 'collection'], 
        description: 'This is the second most authentic collection of hadith after Sahih al-Bukhari. It includes about 4,000 unique hadiths and focuses more on verifying the narrators to ensure authenticity.', 
        image: require('../images/hadith/muslim.png'), 
        buyLink: 'https://amzn.to/3BuLA6h' 
    },
    { 
        id: 1302, 
        title: 'Sunan An-Nasai', 
        author: 'Imam An-Nasai (Compiled)', 
        category: 'hadith', 
        tags: ['hadith', 'arabic', 'english', 'collection'], 
        description: 'This book contains around 5,762 hadiths. It is known for its strict selection criteria, focusing heavily on issues of fiqh and avoiding weak or questionable narrations.', 
        image: require('../images/hadith/nasai.png'), 
        buyLink: 'https://amzn.to/3TJWFqy' 
    },
    { 
        id: 1303, 
        title: 'Sunan Abu Dawud', 
        author: 'Imam Abu Dawood (Compiled)', 
        category: 'hadith', 
        tags: ['hadith', 'arabic', 'english', 'collection'], 
        description: 'This collection contains around 4,800 hadiths. It is widely respected for its focus on legal rulings and jurisprudence (fiqh), and it includes hadiths related to Islamic law and daily life.', 
        image: require('../images/hadith/dawud.png'), 
        buyLink: 'https://amzn.to/3TKQXVf' 
    },
    { 
        id: 1304, 
        title: 'Jami At-Tirmidhi', 
        author: 'Imam al-Tirmidhi (Compiled)', 
        category: 'hadith', 
        tags: ['hadith', 'arabic', 'english', 'collection'], 
        description: 'This collection includes around 3,956 hadiths. It classifies hadiths into different categories based on their authenticity and also includes commentary on their reliability.', 
        image: require('../images/hadith/tirmidhi.png'), 
        buyLink: 'https://amzn.to/3ZAGD69' 
    },
    { 
        id: 1305, 
        title: 'Sunan Ibn Majah', 
        author: 'Imam Ibn Majah (Compiled)', 
        category: 'hadith', 
        tags: ['hadith', 'arabic', 'english', 'collection'], 
        description: ' This collection contains about 4,341 hadiths. Although it includes some weaker narrations, it remains an important source for scholars, especially in jurisprudence.', 
        image: require('../images/hadith/majah.png'), 
        buyLink: 'https://amzn.to/3zyuaW2' 
    },
    
    { 
        id: 1400, 
        title: 'Muhammad: His Life Based on the Earliest Sources', 
        author: 'Martin Lings', 
        category: 'history', 
        tags: ['history',  'english',], 
        description: 'The book offers a vivid and engaging narrative of the Prophet’s life, from his birth and childhood to his mission as the Messenger of Allah, his challenges, and his triumphs. Lings masterfully blends historical accuracy with storytelling, providing readers with a deep understanding of the Prophets character, his teachings, and his profound impact on the world. It is widely praised for its readability and authenticity', 
        image: require('../images/history/lings.png'), 
        buyLink: 'https://amzn.to/4gIfpjX' 
    },
    { 
        id: 1401, 
        title: 'The Sealed Nectar', 
        author: ' Sheikh Safi-ur-Rahman al-Mubarkpuri', 
        category: 'history', 
        tags: ['history',  'english',], 
        description: 'It provides an in-depth, chronological account of the Prophet’s life, from his birth and early years in Mecca to his prophethood, struggles, and triumphs. The book is revered for its well-researched content and accessible narrative, offering readers a clear understanding of Islamic history and the profound impact of the Prophets message.', 
        image: require('../images/history/sealed-nectar.png'), 
        buyLink: 'https://amzn.to/47G5uYj' 
    },
    
    { 
        id: 1500, 
        title: 'This is Why We Pray: A Story About Islam, Salah, and Dua', 
        author: 'Ameenah Muhammad-Diggins', 
        category: 'kids', 
        tags: ['kids',  'english',], 
        description: 'A beautifully illustrated children’s book that explains the significance of prayer in Islam. Through a heartwarming story, it introduces young readers to the five daily prayers (Salah) and the concept of supplication (Dua). The book highlights why Muslims pray, the spiritual connection to Allah, and the peace and comfort that prayer brings. With its engaging narrative and vibrant illustrations, it helps children understand the importance of incorporating prayer into their daily lives while fostering a love for Islamic practices.', 
        image: require('../images/kids/how-to-pray.png'), 
        buyLink: 'https://amzn.to/4eBSQf2' 
    },
    { 
        id: 1501, 
        title: 'Allah for Kids', 
        author: 'The Sincere Seeker Collection', 
        category: 'kids', 
        tags: ['kids',  'english',], 
        description: 'Through colorful illustrations and easy-to-understand language, the book helps children learn about the attributes of Allah, His creation, and His love and care for all beings. It aims to foster a sense of curiosity and faith in young hearts, encouraging them to develop a closer relationship with their Creator from an early age.', 
        image: require('../images/kids/know-allah.png'), 
        buyLink: 'https://amzn.to/3N32ls0' 
    },
    { 
        id: 1502, 
        title: '30 Bedtime Stories For 30 Values From the Quran', 
        author: 'Bachar Karroum', 
        category: 'kids', 
        tags: ['kids',  'english',], 
        description: 'This book is designed to teach Islamic values through engaging stories. Each story is inspired by lessons from the Quran and centers around a specific moral value, such as honesty, kindness, patience, or gratitude. Written in a simple, easy-to-understand style, the book aims to help young readers connect with Islamic teachings and apply them in their daily lives. With one story for each day of the month, it serves as a meaningful way to instill faith-based values at bedtime.', 
        image: require('../images/kids/30-bedtime-stories.png'), 
        buyLink: 'https://amzn.to/3XGdmnW' 
    },
    { 
        id: 1503, 
        title: 'My First Quran with Pictures Part 1', 
        author: ' Shereen Sharief', 
        category: 'kids', 
        tags: ['kids',  'english',], 
        description: 'This interactive book uses colorful illustrations to bring Quranic stories to life for younger children, making learning fun through pictures and lift-the-flap activities​', 
        image: require('../images/kids/first-quraan1.png'), 
        buyLink: 'https://amzn.to/3MYVy2B' 
    },
    { 
        id: 1504, 
        title: 'My First Quran with Pictures Part 2', 
        author: ' Shereen Sharief', 
        category: 'kids', 
        tags: ['kids',  'english',], 
        description: 'This interactive book uses colorful illustrations to bring Quranic stories to life for younger children, making learning fun through pictures and lift-the-flap activities​', 
        image: require('../images/kids/first-quraan2.png'), 
        buyLink: 'https://amzn.to/3ZHLt1A' 
    },
    { 
        id: 1505, 
        title: '30 Days of Learning and Good Deeds', 
        author: 'Ali Gator', 
        category: 'kids', 
        tags: ['kids',  'english',], 
        description: 'A perfect Ramadan activity book, filled with fun challenges and stickers, encouraging kids to perform good deeds year-round​', 
        image: require('../images/kids/learning-good-deeds.png'), 
        buyLink: 'https://amzn.to/3zAuP9i' 
    },
    { 
        id: 1506, 
        title: 'Goodnight Stories from the Quran', 
        author: 'Saniyasnain Khan', 
        category: 'kids', 
        tags: ['kids',  'english',], 
        description: 'A collection of Quranic tales retold in simple language, accompanied by vibrant illustrations, making it an ideal bedtime read​', 
        image: require('../images/kids/goodnight-stories.png'), 
        buyLink: 'https://amzn.to/4ezWpTD' 
    }
];
